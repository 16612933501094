import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainPage from './MainPage';
import AvorPage from './AvorPage';
import CyberProPage from './CyberProPage';
import AntiVorAntiVirus from './AntiVorAntiVirus';
import CreatePage from "./CreatePage";
import './i18n';

function App() {

    return (

        <div className="App">
            <Router>
                <Switch>
                    <Route path="/" exact component={MainPage} />
                    <Route path="/AntiThief" exact component={AvorPage} />
                    <Route path="/CyberProtection" exact component={CyberProPage} />
                    <Route path="/AntiVorAntiVirus" exact component={AntiVorAntiVirus} />
                    <Route path="/product" exact component={CreatePage} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;